import '../App.css';
import React, { useEffect, useRef, useState } from 'react';

import { Auth, Hub } from 'aws-amplify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { intAmplify } from '../aws-amplify-init';
import AuthorizationView from './AuthorizationView';
import { API_ENDPOINTS } from '../utils/UrlUtils';
import { ManageUsersService } from "../components/manage_users/service/ManageUserService";
import moment from 'moment'
import { invokePostApi } from '../utils/api';
import { UploadQueueContextProvider } from "./referral_list/upload/UploadQueueContextApi";

function MainView() {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [amplifyStatus, setAmplifyStatus] = useState({
        status: searchParams.get("success") ? 'SIGN-IN' : 'UNKNOWN',
        apiConfig: null
    });
    const [userAgreement, setUserAgreement] = useState(null);
    const isAmplifyInitialized = useRef(false);
    const signOut = async (sessionTimeout)=>{
        if(sessionTimeout){
            await invokePostApi(API_ENDPOINTS.SESSION_TIMEOUT)
        }
        await invokePostApi(API_ENDPOINTS.LOGOUT);
        //Make a rest api call to let the backend server know sign out
        await Auth.signOut();
        setUser(null);
        setAmplifyStatus({
            status: 'Logout',
            apiConfig: undefined
        })
    }
    useEffect(() => {
        if (!isAmplifyInitialized.current) {
            isAmplifyInitialized.current = true;
            intAmplify().then((response) => {
                try {
                    Auth.currentAuthenticatedUser()
                        .then((userData) => {
                            setUser(userData);
                            setAmplifyStatus({
                                status:
                                    'INITIALIZED',
                                apiConfig: response.apiConfig
                            });

                            const user_id = userData['username'] //userData.signInUserSession.idToken.payload['email']
                            ManageUsersService.getUserAgreement(user_id).then((response) => {
                                if (response) {
                                    setUserAgreement(response)
                                }
                            })
                        })
                        .catch(() => {
                            if (searchParams.get("success")) {
                                navigate('/signin')
                            } else {
                                navigate('/login')
                            }
                        });
                    listenAmplifyEventListener(response.apiConfig);

                } catch (error) {
                    setAmplifyStatus({
                        status:'ERROR',
                        apiConfig: undefined
                    })
                }
            }).catch((err) => {
                setAmplifyStatus({
                    status:'ERROR',
                    apiConfig: undefined
                })
            });
        }
        const listenAmplifyEventListener = (apiCfg) => {
            
            Hub.listen('auth', ({ payload: { event, data } }) => {
                switch (event) {
                    case 'signIn':
                        break;
                    case 'cognitoHostedUI':
                        invokePostApi(API_ENDPOINTS.LOGIN, {
                            "login_date": moment().utc().format('MM/DD/YYYY'),
                            "user_id": data.username
                        });
                        break;
                    case 'signOut':
                        setUser(null);
                        break;
                    case 'signIn_failure':
                    case 'cognitoHostedUI_failure':
                        console.log('Sign in failure', data);
                        break;
                    default:

                }
            });
        }
    }, [navigate,searchParams]);


    return (
        userAgreement && user && amplifyStatus.apiConfig ? (
            <UploadQueueContextProvider apiConfig={amplifyStatus.apiConfig}>
                <AuthorizationView amplifyStatus={amplifyStatus} signOut={signOut} user={user} userAgreement={userAgreement} setUserAgreement={setUserAgreement}></AuthorizationView>
            </UploadQueueContextProvider>
        ) : (
            ['SIGN-IN', 'EROROR'].includes(amplifyStatus.status) ? navigate('/signin') : (
                <div className='buttons-holder'>
                    Loading the Covera Provider Portal. Please wait...<br />
                </div>
            )
        )
    );
}

export default MainView;
